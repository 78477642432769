































import Vue from 'vue';
import CommunicationContainer from '@/container/communication/CommunicationContainer.vue';
import RecordsContainer from '@/container/records/RecordsContainer.vue';
import SettingContainer from '@/container/setting/SettingContainer.vue';
import MemoruTopContainer from '@/container/memoru/MemoruTopContainer.vue'
import { pusherModule } from '@/store/dataModules/pusherModule';
import LocalDataService from '@/service/LocalDataService';
import { auth0Module } from '@/store/dataModules/auth0Module';
import { meModule } from '@/store/dataModules/meModule';
import { MeNurserySchoolResponse, MeResponse } from 'chaild-api/lib';

const url = process.env.VUE_APP_API_URL;
const instanceId = process.env.VUE_APP_BEAMS_INSTANCE_ID;
const apiKey = process.env.VUE_APP_X_API_KEY;

type DataType = {
  tabIndex: number | string;
}

export default Vue.extend({
  components: {
    CommunicationContainer,
    RecordsContainer,
    SettingContainer,
    MemoruTopContainer,
  },

  data: (): DataType => {
    return {
      tabIndex: "message",
    };
  },

  computed: {
    me(): MeResponse | null {
      const me = LocalDataService.getUser();
      return me;
    },
    currentScool(): MeNurserySchoolResponse | undefined {
      if (this.me) {
        let nurserySchool = this.me.nurserySchools.find(nursery => nursery.isDefault === true);
        if (this.me.nurserySchools.length === 0) {
          return undefined
        }
        if (!nurserySchool) {
          nurserySchool = this.me.nurserySchools[0];
        }
        return nurserySchool;
      }
      return undefined;
    },
    shouldShowMessage() {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "連絡");
        if (!messageFeature) {
          return false;
        }
        const length = messageFeature.features.filter((f) => f.isEnabled).length;
        if (length > 0) {
          return true;
        }
      }
      return false;
    },
    shouldShowRecord() {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "記録");
        if (!messageFeature) {
          return false;
        }
        const length = messageFeature.features.filter((f) => f.isEnabled).length;
        if (length > 0) {
          return true;
        }
      }
      return false;
    },
    shouldShowMemoru() {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "MEMORU");
        if (!messageFeature) {
          return false;
        }
        const length = messageFeature.features.filter((f) => f.isEnabled).length;
        if (length > 0) {
          return true;
        }
      }
      return false;
    },
    shouldShowSettings() {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "設定");
        if (!messageFeature) {
          return false;
        }
        const length = messageFeature.features.filter((f) => f.isEnabled).length;
        if (length > 0) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    initBeams: async function() {
      try {
        // rhyme対応
        console.log('start init auth');
        const sessionAndToken = LocalDataService.getPushAuthorizer();
        if ((window as any).webkit && (window as any).webkit.messageHandlers !== undefined) {
          (window as any).webkit.messageHandlers.pusher.postMessage(`${sessionAndToken}`);
        }

        /*  Pusher */
        const accessToken = await auth0Module.getAuth0Token();
        const user = LocalDataService.getUser();
        const PusherPushNotifications = await import('@pusher/push-notifications-web');

        if (instanceId && apiKey && user) {
          let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
          if (!nurserySchool) {
            nurserySchool = user.nurserySchools[0];
          }
          const currentUserId = `${nurserySchool.userId}`;
          const token = await auth0Module.getAuth0Token();
          if (currentUserId && token) {
            LocalDataService.setPushAuthorizer(`${token},${currentUserId}`);
          }
          const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: url + '/push/auth',
            queryParams: {
              user_id: currentUserId,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json; charset=utf-8',
              'x-api-key': apiKey,
            },
          });

          window.navigator.serviceWorker.ready.then(async serviceWorkerRegistration => {
            const beamsClient = new PusherPushNotifications.Client({
              instanceId: instanceId,
              serviceWorkerRegistration,
            });
            beamsClient
              .getUserId()
              .then(userId => {
                if (userId !== currentUserId) {
                  console.log('Unregister for notifications', userId);
                  return beamsClient.stop();
                }
              })
              .catch(console.error);

            beamsClient
              .start()
              .then(() => beamsClient.getDeviceId())
              .then(deviceId => {
                console.log('Device ID:', deviceId);
              })
              .then(() => beamsClient.getDeviceInterests())
              .then(async interests => {
                await Promise.all(
                  interests.map(interest => {
                    // 同じ端末で違うアカウントでログインしていた場合、そのsubscribeが残っているため、解除する
                    if (
                      interest !== `NUR-${currentUserId}` &&
                      interest !== `debug-NUR-${currentUserId}`
                    ) {
                      beamsClient.removeDeviceInterest(interest);
                    }
                  })
                );
                if (!interests.includes(`NUR-${currentUserId}`)) {
                  await beamsClient.addDeviceInterest(`NUR-${currentUserId}`);
                }
                if (!interests.includes(`debug-NUR-${currentUserId}`)) {
                  // debug console用のsubscribe
                  // await beamsClient.addDeviceInterest(`debug-NUR-${currentUserId}`);
                }
              })
              .then(() => beamsClient.getDeviceInterests())
              .then(interest => {
                console.log('PUSHER BEAMS Interest:', interest);
              })
              .then(() => beamsClient.setUserId(currentUserId, beamsTokenProvider))
              .then(() => beamsClient.getUserId())
              .then(userId => {
                console.log('PUSHER BEAMS userId:', userId);
              })
              .catch(error => {
                console.log('error', error);
              });
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {
    this.initBeams();
    meModule.fetchMe();
    if(meModule.tabIndex) {
      const currentIndex = meModule.tabIndex
      this.tabIndex = currentIndex
    }
    // pusherModule.subscribe();
  },
});
