























































































































































































































































import Vue from 'vue';

import { PostMeRequest } from '@/model/Request';
import { memoruAlbumModule } from '@/store/dataModules/memoruAlbumModule';
import { auth0Module } from '@/store/dataModules/auth0Module';
import LocalDataService from '@/service/LocalDataService';
import { MeNurserySchoolResponse, MultimediaAlbumResponse, MultimediaResponse } from 'chaild-api/lib';
import { meModule } from '@/store/dataModules/meModule';
import ApiMemoru from '@/api/ApiMemoru';
import dayjs from 'dayjs';
import { nurseryModule } from '@/store/dataModules/nurseryModule';

interface DataType {
  selectedMedia: MultimediaResponse | null;
  isFabOpen: boolean;
  alubmId: string | number | null;
  isFetching: boolean;
  isDeleting: boolean;
  isUpdating: boolean;
  isUploading: boolean;
  isDeleteDialogOpen: boolean;
  editingAlbum: MultimediaAlbumResponse | null;
}

const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

export default Vue.extend({
  data(): DataType {
    return {
      selectedMedia: null,
      isFabOpen: false,
      alubmId: null,
      isFetching: false,
      isDeleting: false,
      isUpdating: false,
      isUploading: false,
      isDeleteDialogOpen: false,
      editingAlbum: null,
    }
  },
  computed: {
    album() {
      return memoruAlbumModule.album
    },
    contents() {
      return memoruAlbumModule.contents;
    },
    classes() {
      return nurseryModule.allClasses;
    },
  },
  methods: {
    selectMedia(media: MultimediaResponse | null) {
      this.selectedMedia = media;
    },
    imageSelectClicked() {
      const imageInput = this.$refs.imageInput as HTMLInputElement;
      if (imageInput) {
        imageInput.click();
      }
    },
    videoSelectClicked() {
      const videoInput = this.$refs.videoInput as HTMLInputElement;
      if (videoInput) {
        videoInput.click();
      }
    },
    async imageSelected() {
      const imageInput = this.$refs.imageInput as HTMLInputElement;
      if (this.alubmId && imageInput && imageInput.files) {
        const files = Array.from(imageInput.files);
        const albumId = String(this.alubmId);

        this.isUploading = true;

        await Promise.all(files.map((file) => {
          const date = dayjs(file.lastModified).toISOString();
          return ApiMemoru.getUploadEndpoint({
            albumId,
            filename: file.name,
            date,
            category: 'photo'
          }).then((response) => {
            return ApiMemoru.uploadFile({
              url: response.preSignedUploadUrl,
              file: file
            })
          })
        }));

        await sleep(10000)

        this.isUploading = false;

        memoruAlbumModule.listAlbumContents({ albumId })
      }
    },
    async videoSelected() {
      const imageInput = this.$refs.videoInput as HTMLInputElement;
      if (this.alubmId && imageInput && imageInput.files) {
        const files = Array.from(imageInput.files);
        const albumId = String(this.alubmId);

        this.isUploading = true;

        await Promise.all(files.map((file) => {
          const date = dayjs(file.lastModified).toISOString();
          return ApiMemoru.getUploadEndpoint({
            albumId,
            filename: file.name,
            date,
            category: 'movie'
          }).then((response) => {
            return ApiMemoru.uploadFile({
              url: response.preSignedUploadUrl,
              file: file
            })
          })
        }));

        await sleep(10000)

        this.isUploading = false;

        memoruAlbumModule.listAlbumContents({ albumId })
      }
    },
    async deleteMedia() {
      if (this.selectedMedia) {
        this.isDeleting = true;
        await ApiMemoru.deleteAlbumContents({
          multimediaId: this.selectedMedia.multimediaId
        });
        this.isDeleting = false;
        this.isDeleteDialogOpen = false;
        this.selectedMedia = null;
        this.getAlbumAndContents();
      }
    },
    async getAlbumAndContents() {
      if (this.alubmId) {
        this.isFetching = true;
        await Promise.all([
          memoruAlbumModule.getAlbum({albumId: this.alubmId}), 
          memoruAlbumModule.listAlbumContents({ albumId: this.alubmId })
        ])
        this.isFetching = false;
      }
    },
    openAlbumEdit() {
      if (this.album) {
        let album = {...this.album};
        album.availableAfter = dayjs(this.album.availableAfter).format('YYYY-MM-DDTHH:mm:ss');
        if (this.album.availableBefore) {
          album.availableBefore = dayjs(this.album.availableBefore).format('YYYY-MM-DDTHH:mm:ss');
        }
        this.editingAlbum = album;
      }
    },
    closeAlbumEdit() {
      this.editingAlbum = null;
    },
    async updateAlbum() {
      if (this.editingAlbum) {
        this.isUpdating = true;
        await ApiMemoru.updateAlbum({
          albumId: this.editingAlbum.albumId,
          year: this.editingAlbum.year,
          title: this.editingAlbum.title,
          comment: this.editingAlbum.comment,
          nurseryClassIds: this.editingAlbum.nurseryClasses.map((nc) => nc.classId),
          availableAfter: dayjs(this.editingAlbum.availableAfter).toISOString(),
          availableBefore: this.editingAlbum.availableBefore ? dayjs(this.editingAlbum.availableBefore).toISOString() : null
        })
        this.isUpdating = false;

        this.editingAlbum = null;
        this.getAlbumAndContents();
      }
    }
  }, 
  async mounted() {
    const albumId = this.$route.params.albumId as string;
    if (albumId) {
      this.alubmId = albumId;
      this.getAlbumAndContents();
    }

    meModule.setTabIndex('memoru');

    const user = LocalDataService.getUser();
    if (user) {
      let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
      if (!nurserySchool) {
        nurserySchool = user.nurserySchools[0];
      }
      const nurseryId = nurserySchool.nurseryId;
      nurseryModule.getNurseryClass(nurseryId)
    }
  }
});
