













































































































































































































import Vue from 'vue';
import { meModule } from '@/store/dataModules/meModule';
import { attendanceModule } from '@/store/dataModules/attendanceModule';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import AttendanceList from '@/components/AttendanceList.vue';
import DatePager from '@/components/molecules/DatePager.vue';
import VueLoading from '@/components/pages/Loding.vue';
import AbsenceListModal from '@/components/molecules/AbsenceListModal.vue';
import MemoForm from '@/components/molecules/MemoForm.vue';

import { MeNurserySchoolResponse, MeResponse, NurseryChildResponse } from 'chaild-api/lib/index';
import { AbsenceChildType, ClassResponse, GetAttendanceSearchResponse } from '@/model/Response';
import { GetAttendanceSearchRequest, GetAttendanceRequest } from '@/model/Request';
import LocalDataService from '@/service/LocalDataService';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { chatModule } from '@/store/dataModules/chatModule';
dayjs.locale(ja);

export default Vue.extend({
  components: {
    VueLoading,
    AttendanceList,
    AbsenceListModal,
    DatePager,
    MemoForm,
  },
  data: () => {
    return {
      fetchLoading: false,
      isFabOpen: false,
      date: dayjs(),
      tabIndex: 0,
      absenceDialog: false,
      snackbar: false,
      snackBarText: '',
      timeout: 2000,
    };
  },
  computed: {
    chatSummary() {
      if (chatModule.chatSummary && chatModule.chatSummary.date === this.date.format('YYYY-MM-DD')) {
        return chatModule.chatSummary;
      }
      return null;
    },
    clasSummary() {
      if (chatModule.chatSummary && chatModule.chatSummary.date === this.date.format('YYYY-MM-DD')) {
        const summaryies = chatModule.chatSummary.summaries;
        const temp = summaryies.find(clas => clas.isInCharge === true);
        const index = summaryies.findIndex(clas => clas.isInCharge === true);
        if (temp && index && index > 0) {
          summaryies.splice(index, 1);
          summaryies.unshift(temp);
        }
        summaryies.map(summary => {
          const temp = summary.childrenAttendance.filter(
            childrenAttendance =>
              !childrenAttendance.child.quitDate ||
              childrenAttendance.child.quitDate > this.date.format('YYYY-MM-DD')
          );
          summary.childrenAttendance = temp;
          summary.totalChildrenCount = temp.length;
        });
        return summaryies;
      }
      return null;
    },
    reTotalChildrenCount() {
      if (this.clasSummary) {
        let calc = 0;
        this.clasSummary.map(summary => {
          calc = calc + summary.childrenAttendance.length;
        });
        return calc;
      }
    },
    chatsAbsence(): AbsenceChildType[] | [] {
      if (chatModule.chatSummary && chatModule.chatSummary.date === this.date.format('YYYY-MM-DD')) {
        return chatModule.chatSummary.absences;
      }
      return [];
    },
    myClass(): ClassResponse | null {
      const user = LocalDataService.getUser();
      if (user && user.nurserySchools[0].class) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        return nurserySchool.class;
      }
      return null;
    },
    me(): MeResponse | null {
      const me = LocalDataService.getUser();
      return me;
    },
    currentScool(): MeNurserySchoolResponse | undefined {
      if (this.me) {
        let nurserySchool = this.me.nurserySchools.find(nursery => nursery.isDefault === true);
        if (this.me.nurserySchools.length === 0) {
          return undefined
        }
        if (!nurserySchool) {
          nurserySchool = this.me.nurserySchools[0];
        }
        return nurserySchool;
      }
      return undefined;
    },
    features(): Array<{
      name: string; // 機能名
      slug: string; // 機能スラッグ
      isEnabled: boolean; // 機能有効フラグ
    }> {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "連絡");
        if (!messageFeature) {
          return [];
        }
        return messageFeature.features.filter((f) => f.isEnabled);
      }
      return [];
    },
    shouldShowAttendance() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-messaging-attendance");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowMemo() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-messaging-attendance-note");
      if (find) {
        return true;
      }
      return false;
    },
  },
  watch: {
    date: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    showSnackBar(text: string) {
      this.snackBarText = text;
      this.snackbar = true;
    },
    predictFever(value: string) {
      if (value === 'FEVER') {
        return 'red';
      } else if (value === 'MODEST_FEVER') {
        return 'orange';
      } else {
        return null;
      }
    },
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    async init() {
      try {
        this.fetchLoading = true;
        const user = LocalDataService.getUser();
        console.log('user: ', user);
        if (user) {
          let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
          if (!nurserySchool) {
            nurserySchool = user.nurserySchools[0];
          }
          const nurseryId = nurserySchool.nurseryId;
          // await Promise.all([
          //   chatModule.getChatSummary({
          //     nurseryId,
          //     date: this.date.format('YYYY-MM-DD'),
          //   }),
          // ]);
          chatModule.getChatSummary({
            nurseryId,
            date: this.date.format('YYYY-MM-DD'),
          });
        }
      } catch {
      } finally {
        this.$nextTick(() => {
          this.fetchLoading = false;
        });
      }
    },
  },
  async mounted() {
    this.init();
  },
});
